import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/public/change-password',
      name: 'change-password',
      component: () => import('@/views/public/change_pass.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/public/forgot-password',
      name: 'forget-password',
      component: () => import('@/views/public/forget_pass.vue'),
      meta: {
        layout: 'full',
      },
    },
     
    {
      path: '/pending-approval',
      name: 'PendingApproval',
      component: () => import('@/views/loyalty/PendingApproval/PendingApproval.vue'),
      meta: {
        pageTitle: 'Pending Approval',
        breadcrumb: [
          {
            text: 'Pending Approval',
            active: true,
          },
        ],
      },
    },
    {
      path: '/loyalty-history',
      name: 'LoyaltyHistory',
      component: () => import('@/views/loyalty/LoyaltyHistory/LoyaltyHistory.vue'),
      meta: {
        pageTitle: 'Loyalty History',
        breadcrumb: [
          {
            text: 'Loyalty History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/surveyhome',
      name: 'SurveyHome',
      component: () => import('@/views/hr/survey/survey/surveys-list/SurveysList.vue'),
      meta: {
        pageTitle: 'Surveys',
        breadcrumb: [
          {
            text: 'Surveys',
            active: true,
          },
        ],
      },
    },
    {
      path: '/surveyedit',
      name: 'SurveyEdit',
      component: () => import('@/views/hr/survey/survey/surveys-edit/SurveysEdit.vue'),
      meta: {
        pageTitle: 'Survey Detail',
        breadcrumb: [
          {
            text: 'Survey Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/survey-questions',
      name: 'SurveyQuestions',
      component: () => import('@/views/hr/survey/surveyquestion/surveyquestion-list/SurveyQuestionList.vue'),
      meta: {
        pageTitle: 'Survey Questions',
        breadcrumb: [
          {
            text: 'Survey Questions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/survey-subjects',
      name: 'SurveySubjects',
      component: () => import('@/views/hr/survey/surveysubject/surveysubject-list/SurveysubjectList.vue'),
      meta: {
        pageTitle: 'Survey Subjects',
        breadcrumb: [
          {
            text: 'Survey Subjects',
            active: true,
          },
        ],
      },
    },
    {
      path: '/survey-attendees',
      name: 'SurveyAttendees',
      component: () => import('@/views/hr/survey/surveyattendee/surveyattendee-list/SurveyattendeeList.vue'),
      meta: {
        pageTitle: 'Survey Attendees',
        breadcrumb: [
          {
            text: 'Survey Attendees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/survey-result',
      name: 'SurveyResults',
      component: () => import('@/views/hr/survey/surveyresult/surveyresult-list/SurveyresultList.vue'),
      meta: {
        pageTitle: 'Survey Results',
        breadcrumb: [
          {
            text: 'Survey Results',
            active: true,
          },
        ],
      },
    },
    {
      path: '/resultview',
      name: 'ResultViews',
      component: () => import('@/views/hr/survey/resultview/ApexChart.vue'),
      meta: {
        pageTitle: 'Survey Result View',
        breadcrumb: [
          {
            text: 'Survey Result View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orientationtrainings-page',
      name: 'orientationtrainings-page',
      component: () => import('@/views/hr/orientation/training/Training.vue'),
      meta: {
        pageTitle: 'Trainings',
        breadcrumb: [
          {
            text: 'Trainings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/training-edit',
      name: 'training-edit',
      component: () => import('@/views/hr/orientation/training/TrainingEdit.vue'),
      meta: {
        pageTitle: 'Manage Trainings',
        breadcrumb: [
          {
            text: 'Training',
            active: true,
          },
        ],
      },
    },
    {
      path: '/videos-page',
      name: 'videos-page',
      component: () => import('@/views/hr/orientation/video/Video.vue'),
      meta: {
        pageTitle: 'Videos',
        breadcrumb: [
          {
            text: 'Videos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/video-edit',
      name: 'video-edit',
      component: () => import('@/views/hr/orientation/video/VideoEdit.vue'),
      meta: {
        pageTitle: 'Manage Videos',
        breadcrumb: [
          {
            text: 'Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacts-page',
      name: 'contacts-page',
      component: () => import('@/views/hr/orientation/contact/Contact.vue'),
      meta: {
        pageTitle: 'Contacts',
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact-edit',
      name: 'contact-edit',
      component: () => import('@/views/hr/orientation/contact/ContactEdit.vue'),
      meta: {
        pageTitle: 'Manage Contact',
        breadcrumb: [
          {
            text: 'Contact',
            active: true,
          },
        ],
      },
    },
    {
      path: '/store',
      name: 'store',
      component: () => import('@/views/Invoice/Store/StoreView.vue'),
      meta: {
        pageTitle: 'Store',
        breadcrumb: [
          {
            text: 'Store',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice-list',
      name: 'invoice-list',
      component: () => import('@/views/Invoice/InvoiceList.vue'),
      meta: {
        pageTitle: 'Invoice List',
        breadcrumb: [
          {
            text: 'Invoice List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/:id/edit',
      name: 'invoice',
      component: () => import('@/views/Invoice/Invoice.vue'),
      meta: {
        pageTitle: 'Invoice',
        breadcrumb: [
          {
            text: 'Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/:id',
      name: 'invoiceDetails',
      component: () => import('@/views/Invoice/Invoice.vue'),
      meta: {
        pageTitle: 'Invoice',
        breadcrumb: [
          {
            text: 'Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tag-list',
      name: 'tag-list',
      component: () => import('@/views/e-commerce/ProductTags/TagList.vue'),
      meta: {
        pageTitle: 'Product Tags',
        breadcrumb: [
          {
            text: 'Tag List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/model-list',
      name: 'model-list',
      component: () => import('@/views/e-commerce/Brands/Model/ModelList.vue'),
      meta: {
        pageTitle: 'Models',
        breadcrumb: [
          {
            text: 'Models List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brand-list',
      name: 'brand-list',
      component: () => import('@/views/e-commerce/Brands/BrandsList.vue'),
      meta: {
        pageTitle: 'Brands',
        breadcrumb: [
          {
            text: 'Brands List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-list',
      name: 'category-list',
      component: () => import('@/views/e-commerce/Category/CategoryList.vue'),
      meta: {
        pageTitle: 'Category List',
        breadcrumb: [
          {
            text: 'Category List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: () => import('@/views/e-commerce/Product/ProductList.vue'),
      meta: {
        pageTitle: 'Product List',
        breadcrumb: [
          {
            text: 'Product List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-type',
      name: 'product-type',
      component: () => import('@/views/e-commerce/Product/ProductType/ProductType.vue'),
      meta: {
        pageTitle: 'Product Type',
        breadcrumb: [
          {
            text: 'Product Type',
            active: true,
          },
        ],
      },
    },
    {
      path: '/createProduct',
      name: 'create-product',
      component: () => import('@/views/e-commerce/Product/CreateProduct.vue'),
      meta: {
        pageTitle: 'Create Product',
        breadcrumb: [
          {
            text: 'Create Product',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product/:id/edit',
      name: 'ProductEdit',
      component: () => import('@/views/e-commerce/Product/CreateProduct.vue'),
      meta: {
        pageTitle: 'Edit Product',
        breadcrumb: [
          {
            text: 'Edit Product',
          },
        ],
      },
      props: true,
    },
    {
      path: '/product/:id/details',
      name: 'ProductDetails',
      component: () => import('@/views/e-commerce/Product/productDetails.vue'),
      meta: {
        pageTitle: 'Edit Product',
        breadcrumb: [
          {
            text: 'Edit Product',
          },
        ],
      },
      props: true,
    },
    {
      path: '/carts',
      name: 'cart-list',
      component: () => import('@/views/e-commerce/carts/cartsList.vue'),
      meta: {
        pageTitle: 'Carts',
        breadcrumb: [
          {
            text: 'Carts',
          },
        ],
      },
      props: true,
    },
    {
      path: '/carts/:id',
      name: 'cartDetails',
      component: () => import('@/views/e-commerce/carts/cartDetails.vue'),
      meta: {
        pageTitle: 'Cart Details',
        breadcrumb: [
          {
            text: 'Cart Details',
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders-list',
      component: () => import('@/views/e-commerce/orders/ordersList.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
          },
        ],
      },
      props: true,
    },
    {
      path: '/order/:id',
      name: 'orderDetails',
      component: () => import('@/views/e-commerce/orders/orderDetails.vue'),
      meta: {
        pageTitle: 'Order Details',
        breadcrumb: [
          {
            text: 'Order Details',
          },
        ],
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/courses',
      name: 'course-page',
      component: () => import('@/views/course/courses-list/CoursesList.vue'),
      meta: {
        pageTitle: 'Course',
        breadcrumb: [
          {
            text: 'Course',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lectures',
      name: 'course-lectures',
      component: () => import('@/views/lectures/lectures-list/LecturesList.vue'),
      meta: {
        pageTitle: 'Lectures',
        breadcrumb: [
          {
            text: 'Lectures',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lectureslessons',
      name: 'course-lecturelessons',
      component: () => import('@/views/lecturelessons/lecturelessons-list/LectureLessonsList.vue'),
      meta: {
        pageTitle: 'Lessons',
        breadcrumb: [
          {
            text: 'Lectures',
            to: { name: 'course-lectures', params: { lastId: store.getters['app/currentUnit'] } },
          },
          {
            text: 'Lecture Lessons',
            active: true,
          },
        ],
        parent: 'lectures',
        props: true,
      },
    },
    {
      path: '/lessonedit',
      name: 'course-lecturelessons-edit',
      component: () => import('@/views/lecturelessons/lecturelessons-edit/LectureLessonEdit.vue'),
      meta: {
        pageTitle: 'Lessons Edit',
        breadcrumb: [
          {
            text: 'Lectures',
            to: { name: 'course-lectures', params: { lastId: store.getters['app/currentUnit'] } },
          },
          {
            text: 'Lecture Lessons',
            to: { name: 'course-lecturelessons', params: { lastId: store.getters['app/currentLecture'] } },

          },
          {
            text: 'Lessons Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sessions',
      name: 'course-sessions',
      component: () => import('@/views/sessions/sessions-list/SessionsList.vue'),
      meta: {
        pageTitle: 'Sessions',
        breadcrumb: [
          {
            text: 'Sessions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/courses',
      name: 'course-edit',
      component: () => import('@/views/course/courses-edit/CoursesEdit.vue'),
      meta: {
        pageTitle: 'Course',
        breadcrumb: [
          {
            text: 'Course',
            active: true,
          },
        ],
      },
    },
    {
      path: '/coursecategories',
      name: 'course-category-page',
      component: () => import('@/views/course-category/course-category-list/CourseCategorysList.vue'),
      meta: {
        pageTitle: 'Course Category',
        breadcrumb: [
          {
            text: 'Course Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/instructors-page',
      name: 'instructors-page',
      component: () => import('@/views/instructor/instructors-list/InstructorsList.vue'),
      meta: {
        pageTitle: 'Manage Instructors',
        breadcrumb: [
          {
            text: 'Instructors',
            active: true,
          },
        ],
      },
    },
    {
      path: '/trainings',
      name: 'trainings-page',
      component: () => import('@/views/miscellaneous/ComingSoon.vue'),
      meta: {
        pageTitle: 'Trainings',
        breadcrumb: [
          {
            text: 'Trainings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/certifications-page',
      name: 'certifications-page',
      component: () => import('@/views/certificate/certificates-list/CertificatesList.vue'),
      meta: {
        pageTitle: 'Certificates',
        breadcrumb: [
          {
            text: 'Certificate',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps-certificate-edit',
      name: 'apps-certificate-edit',
      component: () => import('@/views/certificate/certificates-edit/CertificatesEdit.vue'),
      meta: {
        pageTitle: 'Certificates',
        breadcrumb: [
          {
            text: 'Certificate',
            active: true,
          },
        ],
      },
    },
    {
      path: '/board-member-page',
      name: 'board-member-page',
      component: () => import('@/views/clinic/boardmembers/instructors-list/InstructorsList.vue'),
      meta: {
        pageTitle: 'Board Members',
        breadcrumb: [
          {
            text: 'Board Members',
            active: true,
          },
        ],
      },
    },
    {
      path: '/board-appointment-page',
      name: 'board-appointment-page',
      component: () => import('@/views/clinic/appointments/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Appointments',
        breadcrumb: [
          {
            text: 'Appointments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clinic-departments-page',
      name: 'clinic-departments-page',
      component: () => import('@/views/clinic/clinicdepartments/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Clinic Departments',
        breadcrumb: [
          {
            text: 'Departments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clinic-specializations-page',
      name: 'clinic-specializations-page',
      component: () => import('@/views/clinic/specializations/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Specializations',
        breadcrumb: [
          {
            text: 'Specializations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/library',
      name: 'library-page',
      component: () => import('@/views/miscellaneous/ComingSoon.vue'),
      meta: {
        pageTitle: 'library',
        breadcrumb: [
          {
            text: 'Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blogs',
      name: 'cms-blogs',
      component: () => import('@/views/cms/blogs/department-list/BlogList.vue'),
      meta: {
        pageTitle: 'Posts',
        breadcrumb: [
          {
            text: 'Posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blogpost-new',
      name: 'blogpost-new',
      component: () => import('@/views/cms/blogs/department-edit/BlogNew.vue'),
      meta: {
        pageTitle: 'New Post',
        breadcrumb: [
          {
            text: 'Create New Post',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-edit',
      name: 'cms-blog-edit',
      component: () => import('@/views/cms/blogs/department-edit/BlogEdit.vue'),
      meta: {
        pageTitle: 'Post Edit',
        breadcrumb: [
          {
            text: 'Post Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-categories',
      name: 'cms-blog-categories',
      component: () => import('@/views/cms/blogcategories/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Post Categories',
        breadcrumb: [
          {
            text: 'Post Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-tags',
      name: 'cms-blog-tags',
      component: () => import('@/views/cms/blogtags/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Post Tags',
        breadcrumb: [
          {
            text: 'Post Tags',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-micro-cms',
      name: 'cms-micro-cms',
      component: () => import('@/views/cms/microcms/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Micro Content',
        breadcrumb: [
          {
            text: 'Micro Content',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-blog-publishers',
      name: 'cms-blog-publishers',
      component: () => import('@/views/cms/blogpublishers/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Publishers',
        breadcrumb: [
          {
            text: 'Publishers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-library',
      name: 'cms-library',
      component: () => import('@/views/cms/library/library-list/LibraryList.vue'),
      meta: {
        pageTitle: 'Library',
        breadcrumb: [
          {
            text: 'Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-library-categories',
      name: 'cms-library-categories',
      component: () => import('@/views/cms/librarycategories/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Library Categories',
        breadcrumb: [
          {
            text: 'Library Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/exam',
      name: 'exam-page',

      component: () => import('@/views/exam/exams-list/ExamsList.vue'),
      meta: {
        pageTitle: 'Exam',
        breadcrumb: [
          {
            text: 'Exam',
            active: true,
          },
        ],
      },
    },
    {
      path: '/examquestions',
      name: 'exam-questions',

      component: () => import('@/views/examquestions/examquestion-list/ExamQuestionList.vue'),
      meta: {
        pageTitle: 'Exam',
        breadcrumb: [
          {
            text: 'Exam',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/choosecompany',
      name: 'choosecompany',
      component: () => import('@/views/choosecompany.vue'),
      props: route => ({

        ...route.params,
      }),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/client-members-page',
      name: 'client-members-page',
      component: () => import('@/views/members/member-list/MembersList.vue'),
      meta: {
        pageTitle: 'Manage Members',
        breadcrumb: [
          {
            text: 'Member/Employee',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-member-edit',
      name: 'client-member-edit',
      component: () => import('@/views/members/member-view/MemberView.vue'),
      meta: {
        pageTitle: 'Manage Member ',
        breadcrumb: [
          {
            text: 'Member/Employe',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-cv-page',
      name: 'admin-cv-page',
      component: () => import('@/views/members/cv-list/MembersList.vue'),
      meta: {
        pageTitle: 'CV Archive AI',
        breadcrumb: [
          {
            text: 'AI CV Search',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-page',
      name: 'admin-users-page',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Admin Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account-migrator-page',
      name: 'account-migrator-page',
      component: () => import('@/views/settings/migration/AccountMigratorPage.vue'),
      meta: {
        pageTitle: 'Account Migrator',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-view',
      name: 'admin-users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to: 'admin-users-page',
          },
          {
            text: 'User Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-edit',
      name: 'admin-users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to: 'admin-users-page',
          },
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/partipiciants-page',
      name: 'partipiciants-page',
      component: () => import('@/views/participant/participant-list/ParticipantsList.vue'),
      meta: {
        pageTitle: 'Manage Participant',
        breadcrumb: [
          {
            text: 'Participant',
            active: true,
          },
        ],
      },
    },
    {
      path: '/partipiciants-bycourse-page',
      name: 'partipiciants-bycourse-page',
      component: () => import('@/views/participant/participant-list/ParticipantListByCourse.vue'),
      meta: {
        pageTitle: 'Course Participants',
        breadcrumb: [
          {
            text: 'Course Participants',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings-page',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Settings',
          },
          {
            text: 'General',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-groups-page',
      name: 'client-groups-page',
      component: () => import('@/views/clientgroups/client-group-list/ClientGroupsList.vue'),
      meta: {
        pageTitle: 'Manage Groups',
        breadcrumb: [
          {
            text: 'Groups',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-group-members',
      name: 'client-group-members',
      component: () => import('@/views/clientgroups/client-group-member-list/ClientGroupsMembersList.vue'),
      meta: {
        pageTitle: 'Manage Group Members',
        breadcrumb: [
          {
            text: 'Group Members',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-branches-page',
      name: 'client-branches-page',
      component: () => import('@/views/branch/branch-list/BranchsList.vue'),
      meta: {
        pageTitle: 'Manage Branches',
        breadcrumb: [
          {
            text: 'Branches',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-pages',
      name: 'cms-pages',
      component: () => import('@/views/cms/pages/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Pages',
        breadcrumb: [
          {
            text: 'Pages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-pages-edit',
      name: 'cms-pages-edit',
      component: () => import('@/views/cms/pages/page-edit/BlogEdit.vue'),
      meta: {
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            text: 'Edit Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-pages-new',
      name: 'cms-pages-new',
      component: () => import('@/views/cms/pages/page-edit/BlogNew.vue'),
      meta: {
        pageTitle: 'New Page',
        breadcrumb: [
          {
            text: 'New Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-feedbacks',
      name: 'cms-feedbacks',
      component: () => import('@/views/cms/feedbacks/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Feedbacks',
        breadcrumb: [
          {
            text: 'Feedbacks',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-suggestion-detail',
      name: 'cms-suggestion-detail',
      component: () => import('@/views/cms/sugestions/participant-edit/ParticipantsEdit.vue'),
      meta: {
        pageTitle: 'Suggestion Detail',
        breadcrumb: [
          {
            text: 'Suggestions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms-suggestions',
      name: 'cms-suggestions',
      component: () => import('@/views/cms/sugestions/participant-list/ParticipantsList.vue'),
      meta: {
        pageTitle: 'Suggestions',
        breadcrumb: [
          {
            text: 'Suggestions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-departments-page',
      name: 'client-departments-page',
      component: () => import('@/views/department/department-list/DepartmentsList.vue'),
      meta: {
        pageTitle: 'Manage Departments',
        breadcrumb: [
          {
            text: 'Departments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-positions-page',
      name: 'client-positions-page',
      component: () => import('@/views/position/position-list/PositionsList.vue'),
      meta: {
        pageTitle: 'Manage Positions',
        breadcrumb: [
          {
            text: 'Positions',
            active: true,
          },
        ],
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  
  if(to.path.includes('public/')) 
    {
      
     return  next();
    } 
    else

    {
      
      

    }
  const isLoggedIn = isUserLoggedIn()
  //   console.log("forward");

  //   var forweardToLogin= localStorage.getItem('needstogoLogin');
  //  console.log("forweardToLogin "+forweardToLogin);

  //   if (forweardToLogin === "true")
  //   {
  //     console.log("in forward logged in ");
  //     if (  to.name !== 'login') {
  //       localStorage.setItem('needstogoLogin',false)
  //       console.log("in change root to logged in ");
  //       return next({ name: 'login' })
  //     }
  //   }

  console.log('isLoggedIn', isLoggedIn)
  console.log('Step 0')

  if (isLoggedIn == null && (to.name === 'login' || to.name === 'choosecompany')) {
    console.log('Step 1')

    next()
  } else if (isLoggedIn == null && to.name !== 'login') {
    console.log('Step 2')
    return next({ name: 'login' })
  } else
  if (isLoggedIn === false && (to.name !== 'login' || to.name !== 'choosecompany')) {
    console.log('Step 3')
    return next({ name: 'login' })
  } else {
    console.log('Step 4')

    return next()
  }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
