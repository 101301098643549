import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/clinic/specialization')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

     
    createDepartment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/clinic/specialization',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateblogtag(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('admin/clinic/specialization',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
