<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
          width="150"
          :src="appLogoImage"
          alt="logo"
        />
          <h2 class="brand-text text-primary ml-1">Vuexy</h2>
        </b-link>

        <b-card-title class="mb-1">Reset Password 🔒</b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords and meet security requirements.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules" v-slot="{ valid }">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|max:32|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="Enter your new password"
                    aria-label="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label="Confirm Password"
              label-for="reset-password-confirm"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="Re-enter your new password"
                    aria-label="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="!valid"
            >
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login-v1' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed, min, max } from 'vee-validate/dist/rules'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
// Extend vee-validate with additional rules
extend('required', { ...required, message: '{_field_} is required' })
extend('min', { ...min, message: '{_field_} must be at least {length} characters' })
extend('max', { ...max, message: '{_field_} cannot exceed {length} characters' })
extend('confirmed', { ...confirmed, message: '{_field_} does not match' })
extend('password', {
  validate: value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value),
  message: 'Password must include at least one uppercase letter, one lowercase letter, and a number',
})

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      appLogoImage: 'https://storage.googleapis.com/cdn.maditao.com/maditao_logo.png',
      userEmail: '',
      cPassword: '',
      password: '',
      password1FieldType: 'password',
      password2FieldType: 'password',
      token: null, 
       
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    // Extract the `ac` token from the query string
    const params = new URLSearchParams(window.location.search)
    this.token = params.get('ac')
    this.userEmail=params.get('em')
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Mock API request for resetting password
          const response =  useJwt.updatePass({
            email: this.userEmail,
            activationCode: this.token,
            password: this.password,
          }).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password Reset Successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              });
              this.$router.push({ path: '/login' });

            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Resetting Password',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
